<template>
    <div>
        <h1 class="page__title">Список платежей</h1>

        <ContentSearch />

        <ContentTable
            :inverse-toggle-icons="true"
            :table-actions="tableActions"
            :headers="tableHeaders"
            :table-rows="tableData"
            :meta="pagination"
            :loading="loading"
        />
    </div>
</template>

<script>
import { errorResponse } from '@/mixins/form/form-mixin';
import ContentTable from '@/components/tables/ContentTable';
import CrudModal from '@/components/modals/CrudModal';
import ContentSearch from '@/components/base/ContentSearch';

export default {
    components: { ContentSearch, CrudModal, ContentTable },
    mixins: [errorResponse],
    data() {
        return {
            loading: false,
            affiliate: false,
            modalOpen: false,
            modalType: null,
            modalTitle: null,
            modalCallback: null,
            modalData: null,
            tableActions: null,
            tableHeaders: [
                { field: 'id', label: '#' },
                { field: 'customer_name', label: 'Покупатель' },
                { field: 'customer_phone', label: 'Телефон пок-я' },
                { field: 'customer_email', label: 'Email пок-я' },
                { field: 'product', label: 'Абонимент' },
                { field: 'product_type', label: 'Тип Абонимента' },
                { field: 'promo_code', label: 'Промокод' },
                { field: 'amount', label: 'Сумма' },
                { field: 'transaction_id', label: 'id Транзакции' },
                { field: 'provider', label: 'Касса' },
                { field: 'status_name', label: 'Статус' },
            ],
            tableData: [],
            pagination: null,
            affiliates: [],
        };
    },
    async created() {
        await this.fetchData(this.$route.query);
    },
    methods: {
        async fetchData(params) {
            try {
                this.loading = true;
                const { data } = await this.axios.get('/payments', { params });
                this.tableData = data.data;
                this.pagination = data.meta;
                this.loading = false;
            } catch (ex) {
                this.loading = false;
                console.log('cant fetch data: ' + ex);
            }
        },
    },
    async beforeRouteUpdate(to, from, next) {
        await this.fetchData({ ...to.query });
        next();
    },
};
</script>

<style></style>
